import React, { useContext, useState } from 'react';
import { fetchJson, saveUserCookie } from '../../helpers';
import { UserContextType } from '../../types';
import { UserContext } from '../../constants';

const Profile = () => {
  const [usernameValid, setUsernameValid] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [newUsername, setNewUsername] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [email, setEmail] = useState('');
  const { user, setUser } = useContext(UserContext) as UserContextType;

  return (
    <main>
      <h2>Profile</h2>
      <h3>Change your username and set a password or email here:</h3>
      <form action='?' method='POST'>
        <input
          type='text'
          className={usernameValid ? 'valid' : 'invalid'}
          placeholder={user.username}
          value={newUsername}
          onChange={(e) => checkUsername(e.target.value)}
          maxLength={20}
          required
          autoFocus
        />
        <br />
        <input
          type='password'
          className={newPasswordValid() ? 'valid' : 'invalid'}
          placeholder='password'
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
        <br />
        <input
          type='email'
          className={emailValid() ? 'valid' : 'invalid'}
          placeholder='e-mail (not required)'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <br />
        <br />
        <input
          type='submit'
          value='Save'
          disabled={!allValid()}
          onClick={(e) => {
            e.preventDefault();
            updateUser();
          }}
        />
      </form>
      <br />
      <h3>Already have an account? Login here:</h3>
      <form action='?' method='POST'>
        <input
          type='text'
          placeholder='username'
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          maxLength={20}
          required
        />
        <br />
        <input type='password' placeholder='password' value={password} onChange={(e) => setPassword(e.target.value)} required />
        <br />
        <br />
        <input
          type='submit'
          value='Login'
          onClick={(e) => {
            e.preventDefault();
            changeAccount();
          }}
        />
      </form>
    </main>
  );

  function updateUser() {
    if (!allValid()) return;
    fetchJson('user/update', 'PATCH', { newUsername, newPassword, email }, (data) => {
      console.log(data);
      if (data) {
        saveUserCookie(newUsername, newPassword);
        setUser({ ...user, ...data });
        setNewUsername('');
        setNewPassword('');
        setEmail('');
      }
    });
  }

  function checkUsername(name: string) {
    setNewUsername(name);
    if (name.length < 3) {
      setUsernameValid(false);
    } else {
      fetchJson('user/get', 'POST', { username: name }, (data) => setUsernameValid(data.length === 0));
    }
  }

  function changeAccount() {
    fetchJson('login', 'POST', { username, password }, (data) => {
      console.log(data);
      if (data) {
        saveUserCookie(username, password);
        setUser({ ...user, ...data });
        setUsername('');
        setPassword('');
      }
    });
  }

  function newPasswordValid() {
    return newPassword.length === 0 || newPassword.length > 7;
  }

  function emailValid() {
    return email.length === 0 || email.match('(.+@.+\\..+)');
  }

  function allValid() {
    return (newUsername.length === 0 || usernameValid) && newPasswordValid() && emailValid();
  }
};
export default Profile;

import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Modal from '../../components/modal';
import { UserContext } from '../../constants';
import { fetchJson } from '../../helpers';
import { Project, UserContextType } from '../../types';
import { projectList } from './projectList';
import './projects.css';

const Projects = () => {
  const { user, setUser } = useContext(UserContext) as UserContextType;
  const [query, setQuery] = useState('');
  const [listView, setListView] = useState(false);
  const [projects, setProjects] = useState(projectList.sort((p1, p2) => p1.name.localeCompare(p2.name)));
  const [trigrams, setTrigrams] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getTrigrams();
    getViews();
  }, []);

  function getProjects() {
    if (projects.length == 0) return <p> No projects found for &quot;{query}&quot;</p>;
    if (listView) {
      return (
        <table>
          <tbody>
            {projects.map((p) => {
              return (
                <tr key={p.name}>
                  <td style={{ textAlign: 'left', minWidth: 150, fontWeight: 'bold' }}>
                    <a href={`/${p.id}`}>
                      {p.name}
                      {p.mobile && ' 📱'}
                    </a>
                  </td>
                  <td style={{ textAlign: 'left' }}>{p.description}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    } else {
      return (
        <div className='flexDiv'>
          {projects.map((p) => {
            const background = 'url(/snaps/' + p.id + '.png) center/290px no-repeat';
            const favorite = user.favorites.includes(p.id);
            return (
              <div key={p.name} className='card'>
                <div style={{ background }}>
                  <input
                    type='button'
                    id='favorite'
                    onClick={() => changeFavorite(p.id)}
                    value={favorite ? '★' : '☆'}
                    style={favorite ? { color: 'yellow' } : { color: 'white' }}
                  />
                  <a href={`/${p.id}`}>
                    <h3>
                      {p.name}
                      {p.mobile && ' 📱'}
                    </h3>
                    <p>{p.description}</p>
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  }

  function changeFavorite(id: string) {
    const newFavorites = user.favorites;
    const index = newFavorites.indexOf(id);
    if (index > -1) {
      newFavorites.splice(index, 1);
    } else {
      if (newFavorites.length >= 4) {
        setShowModal(true);
      } else {
        newFavorites.push(id);
      }
    }
    setUser({ ...user, favorites: newFavorites });
    localStorage.setItem('favorites', JSON.stringify(newFavorites));
    fetchJson('user/favorites', 'PATCH', { username: user.username, favorites: JSON.stringify(newFavorites) });
  }

  function toggleListView() {
    setListView(!listView);
  }
  //TODO dont sort for most trigrams matched but highest ratio
  function getTrigrams() {
    const trigrams: { [key: string]: string[] } = {};
    projectList.forEach((project) => {
      const text =
        '  ' + project.id + '  ' + project.name + '  ' + project.description + ' ' + (project.mobile ? ' mobile 📱 ' : '');
      for (let i = 0; i < text.length - 2; i++) {
        const trigram = text.substring(i, i + 3).toUpperCase();
        let uris: string[] = [];
        if (trigram in trigrams) {
          uris = trigrams[trigram];
        }
        if (!(project.id in uris)) {
          uris.push(project.id);
        }
        trigrams[trigram] = uris;
      }
    });
    setTrigrams(trigrams);
  }

  function getViews() {
    fetchJson('projects', 'GET', null, (data: { project: string; views: number }[]) => {
      const views = new Map<string, number>();
      projectList.forEach((p) => {
        views.set(p.id, 0);
      });
      data.forEach((project) => {
        views.set(project.project, project.views);
      });

      const projects: Project[] = [];
      projectList
        .sort((p1, p2) => (views.get(p2.id) ?? 0) - (views.get(p1.id) ?? 0))
        .forEach((p) => projects.push(Object.assign({}, p)));

      setProjects(projects);
    });
  }

  function search(query: string) {
    setQuery(query);
    if (query == '') {
      getViews();
    } else {
      if (query.length < 3) query = '  ' + query;
      const results: { [key: string]: number } = {};
      for (let i = 0; i < query.length - 2; i++) {
        const trigram = query.substring(i, i + 3).toUpperCase();
        if (trigram in trigrams) {
          trigrams[trigram].forEach((uri) => {
            if (uri in results) {
              results[uri] += 1;
            } else {
              results[uri] = 1;
            }
          });
        }
      }
      setProjects(projectList.filter((project) => project.id in results).sort((p1, p2) => results[p2.id] - results[p1.id]));
    }
  }

  return (
    <main>
      <Helmet>
        <title>Projects - Nightmar</title>
        <meta name='description' content='An overview of all the projects, games and ideas available on Nightmar.' />
      </Helmet>
      <h1>Projects</h1>
      <input
        type='button'
        value={listView ? 'Card View' : 'List View'}
        onClick={() => toggleListView()}
        style={{ width: 100, marginRight: 10 }}
      />
      <input type='text' onChange={(e) => search(e.target.value)} placeholder='Search'></input>
      {getProjects()}
      <Modal isOpen={showModal} setOpen={setShowModal} message='You can only have 4 favorites at a time.' />
    </main>
  );
};
export default Projects;

import React, { useEffect, useState } from 'react';
import { fetchJson } from '../helpers';
import { Score } from '../types';

interface LeaderboardProps {
  game: string;
  order: string;
  header?: boolean;
}

function Leaderboard({ game, order, header = true }: LeaderboardProps) {
  const [scores, setScores] = useState<Score[]>([]);
  let interval: NodeJS.Timeout;

  useEffect(() => {
    clearInterval(interval);
    getScores();
    interval = setInterval(getScores, 3000);
    return () => {
      clearInterval(interval);
    };
  }, [game]);

  function getScores() {
    fetchJson('scores/get', 'POST', { game, order }, (data: Score[]) => {
      setScores(data);
    });
  }

  return (
    <div className='leaderboard'>
      {header && (
        <h2>
          <a href='/leaderboards'>Highscores</a>
        </h2>
      )}
      <table style={{ width: '270px', margin: 'auto' }}>
        <tbody>
          {scores.map((score, index) => (
            <tr key={game + score.username}>
              <td style={{ textAlign: 'left', maxWidth: '170px', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                {index + 1 + '. ' + score.username.substring(0, 15) + ': '}
              </td>
              <td style={{ textAlign: 'right', maxWidth: '90px', whiteSpace: 'nowrap', overflow: 'hidden' }}>{score.score}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Leaderboard;

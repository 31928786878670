import React, { useState } from 'react';
import './leaderboards.css';
import Leaderboard from '../../components/leaderboard';
import { Helmet } from 'react-helmet';

function Leaderboards() {
  const [hexsize, setHexsize] = useState(5);

  return (
    <main>
      <Helmet>
        <title>Leaderboards - Nightmar</title>
        <meta
          name='description'
          content='Compete against other players online and get your highscore onto the leaderboards of the games on nightmarius.com'
        />
      </Helmet>
      <h1>Leaderboards</h1>
      <div className='flexDiv'>
        <div className='board'>
          <h3>
            <a href='circleshooter'>Circle Shooter</a>
          </h3>
          <Leaderboard header={false} game={'circleshooter'} order='DESC' />
        </div>
        <div className='board'>
          <h3>
            <a href='hexmines'>Hexmines</a>
          </h3>
          <input
            type='range'
            min='3'
            max='10'
            step='1'
            value={hexsize}
            onChange={(e) => {
              setHexsize(+e.target.value);
            }}
          />
          <Leaderboard header={false} game={'hexmines' + hexsize} order='ASC' />
        </div>
        <div className='board'>
          <h3>
            <a href='hangman'>Hangman</a>
          </h3>
          <Leaderboard header={false} game={'hangman'} order='DESC' />
        </div>
        <div className='board'>
          <h3>
            <a href='maze'>Maze</a>
          </h3>
          <Leaderboard header={false} game={'maze'} order='DESC' />
        </div>
        <div className='board'>
          <h3>
            <a href='snake'>Snake</a>
          </h3>
          <Leaderboard header={false} game={'snake'} order='DESC' />
        </div>
        <div className='board'>
          <h3>
            <a href='snake2'>Snake V2</a>
          </h3>
          <Leaderboard header={false} game={'snake2'} order='DESC' />
        </div>
      </div>
    </main>
  );
}
export default Leaderboards;

import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { User } from './types';
import { fetchJson, saveUserCookie } from './helpers';
import { guest, UserContext } from './constants';
import { Helmet } from 'react-helmet';
import './App.css';
import Home from './pages/home/home';
import Navigation from './components/navigation/navigation';
import Projects from './pages/projects/projects';
import Contact from './pages/contact/contact';
import Project from './pages/projects/project';
import ErrorPage from './pages/error';
import WgCount from './pages/wgcount/wgcount';
import Stash from './pages/stash/stash';
import PrivateRoute from './components/route/privateRoute';
import Users from './pages/user/users';
import Profile from './pages/user/profile';
import Leaderboards from './pages/leaderboards/leaderboards';

//TODO TEST login on prod
//TODO redirect from unauthorized stash not working when not logged in
//most clicked projects as default favorites in navbar
//migrate images to public folder
//change nginx image cache time
//fix having to commit build files every time
//try node 20
//hyperrealistic shooter game hs kills and ragdolls instantly, body shots kill but can still survive for some time with impaired controls, also body armor
//add filters to projects page: game, demo, mobile, unity, canvas etc.
//add share buttons to project page
//do other fractals like mandelbrot julia set etc.
//fix unity games bug
//do something like r/place
//project page click in search field color bug not worth fixing
//fix nav wrap
//create avatars
//home page replace removed videos with most clicked project snaps or something
//continue with project migration
//fix line endings
//restore chat
//python chat server
//try to hack server
//check mysql injection
//add tests

const title = 'Nightmarius - Games & Ideas';
const imageURL = 'https://nightmarius.com/Icon.png';

const App = () => {
  //the empty username is used to check if the user is logged in for private routes
  const [user, setUser] = useState<User>({ ...guest, favorites: [] });

  useEffect(() => {
    const username = localStorage.getItem('username');
    const password = localStorage.getItem('password');
    const favorites = localStorage.getItem('favorites');
    if (favorites) {
      setUser({ ...guest, ...user, favorites: JSON.parse(favorites) });
    }
    if (username && password) {
      //add salt and pepper
      fetchJson('login', 'POST', { username, password }, (data) => {
        //redirect to home page?
        saveUserCookie(username, password);
        if (data) {
          setUser({ ...data, favorites: JSON.parse(data.favorites) });
        } else {
          setupGuest();
        }
      });
    } else setupGuest();
  }, []);

  function setupGuest() {
    fetchJson('guest', 'GET', null, (data) => {
      if (data) {
        saveUserCookie(data.username, data.password);
        setUser({ ...data, favorites: JSON.parse(data.favorites) });
      } else {
        setupGuest();
      }
    });
  }

  return (
    <div className='App'>
      <Helmet>
        <title>{title}</title>
        <meta
          name='description'
          content='A platform full of games, ideas and other projects for you to explore and share with your friends.'
        />
        <meta property='og:image' content={imageURL} />
        <meta name='twitter:image' content={imageURL} />
        <meta property='twitter:title' content={title} />
        <meta property='twitter:image' content={imageURL} />
        <meta name='twitter:card' content='summary_large_image' />
      </Helmet>
      <BrowserRouter>
        <UserContext.Provider value={{ user, setUser }}>
          <Navigation />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/projects' element={<Projects />} />
            <Route path='/leaderboards' element={<Leaderboards />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/wgcount' element={<WgCount />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/:id' element={<Project />} />
            <Route path='/:id/:gameId' element={<Project />} />
            <Route path='/users' element={<PrivateRoute />}>
              <Route path='/users' element={<Users />} />
            </Route>
            <Route path='/stash' element={<PrivateRoute />}>
              <Route path='/stash' element={<Stash />} />
            </Route>
            <Route path='/error' element={<ErrorPage />} />
            <Route path='/*' element={<Navigate to='/error' />} />
          </Routes>
        </UserContext.Provider>
      </BrowserRouter>
    </div>
  );
};
export default App;

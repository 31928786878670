import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from '../../constants';
import Loading from '../../pages/loading';
import { UserContextType } from '../../types';

interface RouteProps {
  level?: number;
}

const PrivateRoute = (props: RouteProps) => {
  const { user } = useContext(UserContext) as UserContextType;
  if (user.username == '') {
    return <Loading />;
  }
  return user.access > (props.level ?? 1) ? <Outlet /> : <Navigate to='/' />;
};
export default PrivateRoute;

import { Project } from '../../types';

export const projectList: Project[] = [
  {
    id: 'buffering',
    name: 'Animated Buffering Effect',
    description: 'A smooth animated buffering effect for loading screens. Enhance user experience with stylish animations.',
    mobile: true,
  },
  {
    id: 'circlepacking',
    name: 'Interactive Circle Packing',
    description: 'Draw a shape and watch it dynamically fill with colorful, animated circles. A mesmerizing visual effect.',
    mobile: true,
  },
  {
    id: 'circleshooter',
    name: 'Circle Shooter Arcade',
    description: 'A fast-paced shooter game where you destroy moving circles to survive. Test your reflexes now!',
  },
  {
    id: 'clock',
    name: 'Futuristic Digital Clock',
    description: 'A sleek, animated digital clock with a futuristic design. View time like never before!',
    mobile: true,
  },
  {
    id: 'collatztree',
    name: 'Collatz Conjecture Tree',
    description: 'Explore the Collatz theorem visually with this fractal-based interactive tree.',
    mobile: true,
  },
  {
    id: 'colorwell',
    name: 'Infinite Color Well',
    description: 'Watch an endless stream of colorful shapes emerge and transform in a visually stunning effect.',
    mobile: true,
  },
  {
    id: 'converter',
    name: 'Number & Code Converter',
    description: 'Easily convert between ASCII, hexadecimal, decimal, binary, and base64 with this simple tool.',
    mobile: true,
  },
  {
    id: 'cubelab',
    name: 'Cube Lab Puzzle Game',
    description: 'Solve challenging puzzles in a cubic world using shadows, colors, and logic. A unique 3D experience!',
  },
  {
    id: 'fanorona',
    name: 'Fanorona Strategy Game',
    description: 'Play the classic two-player strategy board game from Madagascar. Outsmart your opponent and win!',
    mobile: true,
  },
  {
    id: 'fractal',
    name: 'Random Particle Fractal',
    description: 'Watch a mesmerizing fractal form as particles move randomly and stick together to create patterns.',
    mobile: true,
  },
  {
    id: 'fractaltree',
    name: 'Colorful Fractal Tree Generator',
    description: 'Generate stunning fractal trees using a recursive algorithm. Create your own unique patterns!',
    mobile: true,
  },
  {
    id: 'fruitwars',
    name: 'Fruit Wars Strategy Game',
    description: 'Control a fruit, conquer trees, and dominate the battlefield in this competitive strategy game.',
    mobile: true,
  },
  {
    id: 'gallery',
    name: 'Digital Art Gallery',
    description: 'Explore a curated collection of generative and digital artwork. A showcase of creativity and design.',
    mobile: true,
  },
  {
    id: 'hangman',
    name: 'Online Multiplayer Hangman',
    description: 'Guess words and challenge friends in this exciting online multiplayer Hangman game. Play now!',
    mobile: true,
  },
  {
    id: 'hexmines',
    name: 'Hexagonal Minesweeper Game',
    description: 'A modern twist on classic Minesweeper, featuring a challenging hexagonal grid. Can you clear it all?',
    mobile: true,
  },
  {
    id: 'mandelbrot',
    name: 'Interactive Mandelbrot Set Explorer',
    description: 'Zoom into the Mandelbrot fractal and explore infinite complexity with interactive controls.',
    mobile: true,
  },
  {
    id: 'maze',
    name: 'Random Maze Game',
    description: 'Navigate through randomly generated mazes and reach the goal. Can you solve them all?',
    mobile: true,
  },
  {
    id: 'nightstand',
    name: '3D Customizable Nightstand',
    description: 'Design your own nightstand with customizable materials and accessories using Three.js.',
    mobile: true,
  },
  {
    id: 'pascal',
    name: "Pascal's Triangle & Fractals",
    description: "Discover patterns in Pascal's Triangle and generate fractals like the Sierpiński Triangle.",
    mobile: true,
  },
  {
    id: 'picevo',
    name: 'AI Picture Evolution',
    description: 'Watch a genetic algorithm evolve an image using a set of polygons. A fascinating AI experiment!',
    mobile: true,
  },
  {
    id: 'rago',
    name: 'Rago.io - Multiplayer Battle',
    description: 'Compete in a fast-paced Agar.io-style multiplayer battle game. Can you dominate the arena?',
  },
  {
    id: 'snake',
    name: 'Classic Snake Game',
    description: 'Play the timeless Snake game! Eat food, grow longer, and avoid crashing in this retro classic.',
  },
  {
    id: 'snake2',
    name: 'Snake V2 - Modern Snake Game',
    description: 'An upgraded version of the classic Snake game with new mechanics and challenges!',
  },
  {
    id: 'vampirism',
    name: 'Vampire Survival Puzzle',
    description: 'Race against time to find your coffin before sunrise. Can you survive the night?',
  },
  {
    id: 'vill',
    name: 'Vill - Open World Adventure',
    description: 'Explore a procedurally generated world, battle enemies, and uncover hidden mysteries in Vill!',
    mobile: true,
  },
  {
    id: 'voidfencer',
    name: 'Void Fencer - 2 Player Duel',
    description: 'Engage in a unique fencing duel where the lights are out, and you rely on a flashlight!',
  },
];

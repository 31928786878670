import React, { Suspense, lazy, useEffect, useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { projectList } from './projectList';
import { Helmet } from 'react-helmet';
import Loading from '../loading';
import { fetchJson } from '../../helpers';

const Project = () => {
  const { id } = useParams();
  const Project = useMemo(() => lazy(() => import(`./pages/${id}.tsx`)), [id]);
  const project = projectList.find((p) => p.id === id);
  const title = project?.name + ' - Nightmarius';
  const desc = project?.description;
  const imageURL = 'https://nightmarius.com/snaps/' + project?.id + '.png';
  const mobile = window.innerWidth < 700;

  useEffect(() => {
    fetchJson('project', 'POST', { project: id });
  }, []);

  if (!projectList.some((p) => p.id === id)) return <Navigate to='/error' />;
  return (
    <Suspense fallback={<Loading />}>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={desc} />
        <meta property='og:image' content={imageURL} />
        <meta name='twitter:image' content={imageURL} />
        <meta property='twitter:title' content={title} />
        <meta property='twitter:image' content={imageURL} />
        <meta name='twitter:card' content='summary_large_image' />
      </Helmet>
      {mobile && !project?.mobile && <h2 id='mobileWarning'>This project is not supported on mobile devices!</h2>}
      <Project />
    </Suspense>
  );
};
export default Project;
